/**
 * Tooltip
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/
*/

export default function (element) {
    let tooltipTriggerList = [].slice.call(element.querySelectorAll('[data-bs-toggle="tooltip"]'));

    //let tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {trigger: 'hover'}));
}
