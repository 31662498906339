/**
 * Pell based rich text editing
 * @requires https://github.com/jaredreich/pell
 */

function initializePellEditor(rootElement) {
    if (rootElement.dataset.pellstate !== 'ready') {
        rootElement.dataset.pellstate = 'ready';
        let valueElement = rootElement.querySelector('.pell-value');
        let editorElement = rootElement.querySelector('.pell-editor-element');
        let pellEditor = pell.init({
            element: editorElement,
            onChange: function (html) {
                valueElement.value = html;
            },
            actions: [
                'bold',
                'italic',
                'underline',
                'heading2',
                'paragraph',
                'olist',
                'ulist',
                'link'
            ]
        });
        pellEditor.content.innerHTML = valueElement.value;
        pellEditor.content.querySelectorAll('*').forEach(ele => {
            ele.className = '';
            ele.removeAttribute('style');
        })
        pellEditor.content.style.height = 'fit-content'
    }
}

export default function (element) {
    let pellEditors = element.querySelectorAll('.pell-editor');
    for (let i = 0; i < pellEditors.length; i++) {
        initializePellEditor(pellEditors[i]);
    }
}
