/**
 * @typedef Toast
 * @type {object}
 * @property {string} text
 * @property {null|string} head
 * @property {boolean} error
 * @property {null|number|string} id
 * @property {boolean} autohide
 */

/**
 * Create a toast and show it
 * @param {Toast}
 */
export function createToast({ text, head, error, id, autohide }) {
    const idStr = (typeof id === 'string' || typeof id === 'number') ?
        `toast-${id}`.trim() :
        'toast';

    const existing = document.getElementById(idStr);
    if (idStr !== 'toast' && existing !== null) {
        const counter = document.getElementById(`${idStr}-count`);
        const last = counter.innerHTML.match(/\d+/);
        counter.innerHTML = `x${(last ? Number(last[0]) : 1) + 1}`;

        // fake event to reset internal timeout
        existing.dispatchEvent(new FocusEvent('focusin'));
        existing.dispatchEvent(new FocusEvent('focusout'));
        return;
    }

    const element = document.createElement('div');
    element.id = idStr;
    element.classList.add('toast');
    element.setAttribute('data-bs-autohide', autohide.toString());

    {
        const header = element.appendChild(document.createElement('div'));
        header.classList.add(
            'toast-header',
            'text-light',
            'fs-6',
            'fw-bold',
            error === true ? 'bg-warning' : 'bg-success',
        );

        const icon = header.appendChild(document.createElement('i'));
        icon.classList.add('me-2', error ? 'ci-announcement' : 'ci-check-circle');

        const grid = header.appendChild(document.createElement('div'));
        grid.classList.add('d-grid', 'w-100');
        grid.style.gridTemplateColumns = '1fr auto auto';
        grid.style.columnGap = '0.5rem';

        const text = grid.appendChild(document.createElement('span'));
        text.innerHTML = head;

        const count = grid.appendChild(document.createElement('span'));
        count.id = `${idStr}-count`;

        const btn = grid.appendChild(document.createElement('button'));
        btn.type = 'button';
        btn.classList.add('btn-close', 'm-auto')
        btn.setAttribute('aria-label', 'close');
        btn.onclick = _ => bootstrap.Toast.getInstance(element).hide();
    }

    {
        const body = element.appendChild(document.createElement('div'));
        body.classList.add('toast-body');
        body.innerHTML = text;
    }

    document.getElementById('toast-container')
        .appendChild(element);

    const toast = new bootstrap.Toast(element);
    setTimeout(() => toast.show(), 0);
}

const KEY = 'data-app-toasts-queue';

/**
 * @param {Element} element
 */
export function toastQueue(element) {
    element.querySelectorAll(`[${KEY}]`).forEach(toastElement => {
        const dataStr = toastElement.getAttribute(KEY).replace(/'/g, '"');
        toastElement.removeAttribute(KEY);

        /** @type {{queue: Toast[], deferred: boolean}} */
        const { queue, deferred } = JSON.parse(dataStr);

        if (deferred) {
            setTimeout(queue.forEach(toast => createToast(toast)), 3000);
        } else {
            queue.forEach(toast => createToast(toast));
        }
    });
}
