/**
 * Privacy button
 */

function updatePrivacyButton(buttonElement) {
    if (buttonElement.dataset.status === 'initialized') return;
    let originalPrivacyButton = null;
    if (window.originalPrivacyButton) {
        originalPrivacyButton = window.originalPrivacyButton;
    } else {
        originalPrivacyButton = document.getElementById('usercentrics-root');
        if (originalPrivacyButton) originalPrivacyButton = originalPrivacyButton.shadowRoot
        if (originalPrivacyButton) originalPrivacyButton = originalPrivacyButton.firstElementChild
        if (originalPrivacyButton) originalPrivacyButton = originalPrivacyButton.getElementsByTagName('button')[0];
        if (originalPrivacyButton) {
            window.originalPrivacyButton = originalPrivacyButton;
        } else {
            setTimeout(() => updatePrivacyButton(buttonElement), 10)
            return
        }
    }

    const svg = originalPrivacyButton.getElementsByTagName('svg')[0].cloneNode(true);
    const iconElementId = buttonElement.dataset.iconElementId;
    if (iconElementId) {
        const iconElement = document.getElementById(iconElementId);
        if (iconElement) {
            iconElement.append(svg);
        }
    } else {
        buttonElement.append(svg);
    }

    buttonElement.addEventListener('click', () => originalPrivacyButton.click());
    function checkMenu() {
        originalPrivacyButton.hidden = Boolean(
            buttonElement.offsetWidth || buttonElement.offsetHeight || buttonElement.offsetParent
        );
    }
    window.addEventListener('resize', checkMenu);
    checkMenu();
    buttonElement.dataset.status = 'initialized';
}

export default function (element) {
    let privacyButtonList = [].slice.call(element.querySelectorAll('.privacy-button'));
    let popoverList = privacyButtonList.map((privacyButtonElement) => updatePrivacyButton(privacyButtonElement));
}
