
        /**
         * Cartzilla | Bootstrap E-Commerce Template
         * Copyright 2022 Createx Studio
         * Theme core scripts
         *
         * @author Createx Studio
         * @version 2.4.0
         */
        
(function () {
    'use strict';

    /**
     * Enable sticky behavior of navigation bar on page scroll
    */

    function stickyNavbar (element) {

        let navbar = element.querySelector('.navbar-sticky');

        if (navbar == null) return;

        let navbarClass = navbar.classList,
            navbarH = navbar.offsetHeight,
            scrollOffset = 500;

        window.addEventListener('scroll', (e) => {

            if (navbar.classList.contains('position-absolute')) {
                if (e.currentTarget.pageYOffset > scrollOffset) {
                    navbar.classList.add('navbar-stuck');
                } else {
                    navbar.classList.remove('navbar-stuck');
                }
            } else {
                if (e.currentTarget.pageYOffset > scrollOffset) {
                    document.body.style.paddingTop = navbarH + 'px';
                    navbar.classList.add('navbar-stuck');
                } else {
                    document.body.style.paddingTop = '';
                    navbar.classList.remove('navbar-stuck');
                }
            }
        });

    }

    /**
     * Menu toggle for 3 level navbar stuck state
    */

    function stuckNavbarMenuToggle (element) {

        let toggler = element.querySelector('.navbar-stuck-toggler'),
            stuckMenu = element.querySelector('.navbar-stuck-menu');

        if (toggler == null) return;

        toggler.addEventListener('click', function (e) {
            stuckMenu.classList.toggle('show');
            e.preventDefault();
        });

    }

    /**
     * Cascading (Masonry) grid layout
     *
     * @requires https://github.com/desandro/imagesloaded
     * @requires https://github.com/Vestride/Shuffle
    */

    function masonryGrid (element) {

        let grid = element.querySelectorAll('.masonry-grid'),
            masonry;

        if (grid === null) return;

        for (let i = 0; i < grid.length; i++) {
            masonry = new Shuffle(grid[i], {
                itemSelector: '.masonry-grid-item',
                sizer: '.masonry-grid-item'
            });

            imagesLoaded(grid[i]).on('progress', () => {
                masonry.layout();
            });
        }
    }

    /**
     * Toggling password visibility in password input
    */

    function passwordVisibilityToggle (element) {

        let elements = element.querySelectorAll('.password-toggle');

        for (let i = 0; i < elements.length; i++) {
            let passInput = elements[i].querySelector('.form-control'),
                passToggle = elements[i].querySelector('.password-toggle-btn');

            passToggle.addEventListener('click', (e) => {

                if (e.target.type !== 'checkbox') return;
                if (e.target.checked) {
                    passInput.type = 'text';
                } else {
                    passInput.type = 'password';
                }

            }, false);
        }
    }

    /**
     * Pell based rich text editing
     * @requires https://github.com/jaredreich/pell
     */

    function initializePellEditor(rootElement) {
        if (rootElement.dataset.pellstate !== 'ready') {
            rootElement.dataset.pellstate = 'ready';
            let valueElement = rootElement.querySelector('.pell-value');
            let editorElement = rootElement.querySelector('.pell-editor-element');
            let pellEditor = pell.init({
                element: editorElement,
                onChange: function (html) {
                    valueElement.value = html;
                },
                actions: [
                    'bold',
                    'italic',
                    'underline',
                    'heading2',
                    'paragraph',
                    'olist',
                    'ulist',
                    'link'
                ]
            });
            pellEditor.content.innerHTML = valueElement.value;
            pellEditor.content.querySelectorAll('*').forEach(ele => {
                ele.className = '';
                ele.removeAttribute('style');
            });
            pellEditor.content.style.height = 'fit-content';
        }
    }

    function pellEditor (element) {
        let pellEditors = element.querySelectorAll('.pell-editor');
        for (let i = 0; i < pellEditors.length; i++) {
            initializePellEditor(pellEditors[i]);
        }
    }

    /**
     * Custom file drag and drop area
    */

    function fileDropArea (element) {

        const fileArea = element.querySelectorAll('.file-drop-area');

        for (let i = 0; i < fileArea.length; i++) {
            let input = fileArea[i].querySelector('.file-drop-input'),
                message = fileArea[i].querySelector('.file-drop-message'),
                icon = fileArea[i].querySelector('.file-drop-icon'),
                button = fileArea[i].querySelector('.file-drop-btn');

            button.addEventListener('click', function () {
                input.click();
            });

            input.addEventListener('change', function () {
                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        let fileData = e.target.result;
                        let fileName = input.files[0].name;
                        message.innerHTML = fileName;

                        if (fileData.startsWith('data:image')) {

                            let image = new Image();
                            image.src = fileData;

                            image.onload = function () {
                                icon.className = 'file-drop-preview img-thumbnail rounded';
                                icon.innerHTML = '<img src="' + image.src + '" alt="' + fileName + '">';
                            };

                        } else if (fileData.startsWith('data:video')) {
                            icon.innerHTML = '';
                            icon.className = '';
                            icon.className = 'file-drop-icon ci-video';

                        } else {
                            icon.innerHTML = '';
                            icon.className = '';
                            icon.className = 'file-drop-icon ci-document';
                        }
                    };
                    reader.readAsDataURL(input.files[0]);
                }

            });
        }
    }

    /**
     * Form validation
    */

    function formValidation (element) {

        const selector = 'needs-validation';

        window.addEventListener('load', () => {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            let forms = document.getElementsByClassName(selector);
            // Loop over them and prevent submission
            let validation = Array.prototype.filter.call(forms, (form) => {
                form.addEventListener('submit', (e) => {
                    if (form.checkValidity() === false) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            });
        }, false);
    }

    /**
     * Anchor smooth scrolling
     * @requires https://github.com/cferdinandi/smooth-scroll/
    */

    function smoothScroll (element) {

        let selector = '[data-scroll]',
            fixedHeader = '[data-scroll-header]',
            scroll = new SmoothScroll(selector, {
                speed: 800,
                speedAsDuration: true,
                offset: 40,
                header: fixedHeader,
                updateURL: false
            });

    }

    /**
     * Animate scroll to top button in/off view
    */

    function scrollTopButton (element) {

        let ele = element.querySelector('.btn-scroll-top'),
            scrollOffset = 600;

        if (ele == null) return;

        let offsetFromTop = parseInt(scrollOffset, 10);

        window.addEventListener('scroll', (e) => {
            if (e.currentTarget.pageYOffset > offsetFromTop) {
                ele.classList.add('show');
            } else {
                ele.classList.remove('show');
            }
        });
    }

    /**
     * Tooltip
     * @requires https://getbootstrap.com
     * @requires https://popper.js.org/
    */

    function tooltip (element) {
        let tooltipTriggerList = [].slice.call(element.querySelectorAll('[data-bs-toggle="tooltip"]'));

        //let tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {trigger: 'hover'}));
    }

    /**
     * Popover
     * @requires https://getbootstrap.com
     * @requires https://popper.js.org/
    */

    function popover (element) {

        let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

        let popoverList = popoverTriggerList.map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

    }

    /**
     * Privacy button
     */

    function updatePrivacyButton(buttonElement) {
        if (buttonElement.dataset.status === 'initialized') return;
        let originalPrivacyButton = null;
        if (window.originalPrivacyButton) {
            originalPrivacyButton = window.originalPrivacyButton;
        } else {
            originalPrivacyButton = document.getElementById('usercentrics-root');
            if (originalPrivacyButton) originalPrivacyButton = originalPrivacyButton.shadowRoot;
            if (originalPrivacyButton) originalPrivacyButton = originalPrivacyButton.firstElementChild;
            if (originalPrivacyButton) originalPrivacyButton = originalPrivacyButton.getElementsByTagName('button')[0];
            if (originalPrivacyButton) {
                window.originalPrivacyButton = originalPrivacyButton;
            } else {
                setTimeout(() => updatePrivacyButton(buttonElement), 10);
                return
            }
        }

        const svg = originalPrivacyButton.getElementsByTagName('svg')[0].cloneNode(true);
        const iconElementId = buttonElement.dataset.iconElementId;
        if (iconElementId) {
            const iconElement = document.getElementById(iconElementId);
            if (iconElement) {
                iconElement.append(svg);
            }
        } else {
            buttonElement.append(svg);
        }

        buttonElement.addEventListener('click', () => originalPrivacyButton.click());
        function checkMenu() {
            originalPrivacyButton.hidden = Boolean(
                buttonElement.offsetWidth || buttonElement.offsetHeight || buttonElement.offsetParent
            );
        }
        window.addEventListener('resize', checkMenu);
        checkMenu();
        buttonElement.dataset.status = 'initialized';
    }

    function privacy (element) {
        let privacyButtonList = [].slice.call(element.querySelectorAll('.privacy-button'));
        let popoverList = privacyButtonList.map((privacyButtonElement) => updatePrivacyButton(privacyButtonElement));
    }

    /**
     * Toast
     * @requires https://getbootstrap.com
    */

    function toast (element) {

        let toastElList = [].slice.call(document.querySelectorAll('.toast'));

        let toastList = toastElList.map((toastEl) => new bootstrap.Toast(toastEl));

    }

    /**
     * Disable dropdown autohide when select is clicked
    */

    function disableDropdownAutohide (element) {

        let elements = element.querySelectorAll('.disable-autohide .form-select');

        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', (e) => {
                e.stopPropagation();
            });
        }
    }

    /**
     * Content carousel with extensive options to control behaviour and appearance
     * @requires https://github.com/ganlanyuan/tiny-slider
    */

    function carousel (element) {
        const carousels = element.querySelectorAll('.tns-carousel .tns-carousel-inner');
        for (const carousel of carousels) {
            const defaults = {
                loop: false,
                container: carousel,
                controlsText: ['<i class="ci-arrow-left"></i>', '<i class="ci-arrow-right"></i>'],
                navPosition: 'bottom',
                mouseDrag: true,
                speed: 500,
                autoplayHoverPause: true,
                autoplayButtonOutput: false,
            };
            const { carouselOptions } = carousel.dataset;

            const userOptions = carouselOptions !== undefined ? JSON.parse(carouselOptions) : {};
            if (!carousel.tns) {
                const lastHeadElement = document.head.lastElementChild;
                carousel.tns = tns({ ...defaults, ...userOptions });
                const newStyleElement = document.head.lastElementChild;
                if (lastHeadElement !== newStyleElement && newStyleElement.tagName === 'STYLE') {
                    newStyleElement.carouselElement = carousel;
                    newStyleElement.dataset.owner = 'carousel';
                }
            }
        }
        for (const style of document.querySelectorAll('style[data-owner="carousel"]')) {
            if (!document.body.contains(style.carouselElement)) style.remove();
        }
    }

    /**
     * Lightbox component for presenting various types of media
     * @requires https://github.com/sachinchoolur/lightgallery.js
    */

    function gallery (element) {

        let gallery = element.querySelectorAll('.gallery');
        if (gallery.length) {
            for (let i = 0; i < gallery.length; i++) {
                lightGallery(gallery[i], {
                    selector: '.gallery-item',
                    download: false,
                    videojs: true,
                    youtubePlayerParams: {
                        modestbranding: 1,
                        showinfo: 0,
                        rel: 0
                    },
                    vimeoPlayerParams: {
                        byline: 0,
                        portrait: 0,
                        color: 'fe696a'
                    }
                });
            }
        }
    }

    /**
     * Shop product page gallery with thumbnails
     * @requires https://github.com/sachinchoolur/lightgallery.js
    */

    function productGallery (element) {

        let gallery = element.querySelectorAll('.product-gallery');
        if (gallery.length) {

            for (let i = 0; i < gallery.length; i++) {

                let thumbnails = gallery[i].querySelectorAll('.product-gallery-thumblist-item:not(.video-item)'),
                    previews = gallery[i].querySelectorAll('.product-gallery-preview-item'),
                    videos = gallery[i].querySelectorAll('.product-gallery-thumblist-item.video-item');


                for (let n = 0; n < thumbnails.length; n++) {
                    thumbnails[n].addEventListener('click', changePreview);
                }

                // Changer preview function
                function changePreview(e) {
                    e.preventDefault();
                    for (let i = 0; i < thumbnails.length; i++) {
                        previews[i].classList.remove('active');
                        thumbnails[i].classList.remove('active');
                    }
                    this.classList.add('active');
                    gallery[i].querySelector(this.getAttribute('href')).classList.add('active');
                }

                // Video thumbnail - open video in lightbox
                for (let m = 0; m < videos.length; m++) {
                    lightGallery(videos[m], {
                        selector: 'this',
                        download: false,
                        videojs: true,
                        youtubePlayerParams: {
                            modestbranding: 1,
                            showinfo: 0,
                            rel: 0,
                            controls: 0
                        },
                        vimeoPlayerParams: {
                            byline: 0,
                            portrait: 0,
                            color: 'fe696a'
                        }
                    });
                }
            }
        }

    }

    /**
     * Image zoom on hover (used inside Product Gallery)
     * @requires https://github.com/imgix/drift
    */

    function imageZoom (element) {

        let images = element.querySelectorAll('.image-zoom');

        for (let i = 0; i < images.length; i++) {
            new Drift(images[i], {
                paneContainer: images[i].parentElement.querySelector('.image-zoom-pane')
            });
        }

    }

    /**
     * Countdown timer
    */

    function countdown (element) {

        let coundown = element.querySelectorAll('.countdown');

        if (coundown == null) return;

        for (let i = 0; i < coundown.length; i++) {

            let endDate = coundown[i].dataset.countdown,
                daysVal = coundown[i].querySelector('.countdown-days .countdown-value'),
                hoursVal = coundown[i].querySelector('.countdown-hours .countdown-value'),
                minutesVal = coundown[i].querySelector('.countdown-minutes .countdown-value'),
                secondsVal = coundown[i].querySelector('.countdown-seconds .countdown-value'),
                days, hours, minutes, seconds;

            endDate = new Date(endDate).getTime();

            if (isNaN(endDate)) return;

            setInterval(calculate, 1000);

            function calculate() {
                let startDate = new Date().getTime();

                let timeRemaining = parseInt((endDate - startDate) / 1000);

                if (timeRemaining >= 0) {
                    days = parseInt(timeRemaining / 86400);
                    timeRemaining = (timeRemaining % 86400);

                    hours = parseInt(timeRemaining / 3600);
                    timeRemaining = (timeRemaining % 3600);

                    minutes = parseInt(timeRemaining / 60);
                    timeRemaining = (timeRemaining % 60);

                    seconds = parseInt(timeRemaining);

                    if (daysVal != null) {
                        daysVal.innerHTML = parseInt(days, 10);
                    }
                    if (hoursVal != null) {
                        hoursVal.innerHTML = hours < 10 ? '0' + hours : hours;
                    }
                    if (minutesVal != null) {
                        minutesVal.innerHTML = minutes < 10 ? '0' + minutes : minutes;
                    }
                    if (secondsVal != null) {
                        secondsVal.innerHTML = seconds < 10 ? '0' + seconds : seconds;
                    }

                } else {
                    return;
                }
            }
        }
    }

    /**
     * Charts
     * @requires https://github.com/gionkunz/chartist-js
    */

    function charts (element) {

        let lineChart = element.querySelectorAll('[data-line-chart]'),
            barChart = element.querySelectorAll('[data-bar-chart]'),
            pieChart = element.querySelectorAll('[data-pie-chart]');

        let sum = function (a, b) {
            return a + b
        };

        if (lineChart.length === 0 && barChart.length === 0 && pieChart.length === 0) return;

        // Create <style> tag and put it to <head> for changing colors of charts via data attributes
        const oldStyleElement = document.querySelector('style[data-owner="charts"]');
        if (oldStyleElement) oldStyleElement.remove();
        let head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style'),
            css;
        style.dataset.owner = 'charts';
        head.appendChild(style);


        // Line chart
        for (let i = 0; i < lineChart.length; i++) {

            let data = JSON.parse(lineChart[i].dataset.lineChart),
                options = (lineChart[i].dataset.options !== undefined) ? JSON.parse(lineChart[i].dataset.options) : '',
                seriesColor = lineChart[i].dataset.seriesColor,
                userColors;

            lineChart[i].classList.add('line-chart-' + i);

            if (seriesColor !== undefined) {

                userColors = JSON.parse(seriesColor);

                for (let n = 0; n < userColors.colors.length; n++) {
                    css = `
          .line-chart-${i} .ct-series:nth-child(${n + 1}) .ct-line,
          .line-chart-${i} .ct-series:nth-child(${n + 1}) .ct-point {
            stroke: ${userColors.colors[n]} !important;
          }
        `;
                    style.appendChild(document.createTextNode(css));
                }
            }

            new Chartist.Line(lineChart[i], data, options);
        }


        // Bar chart
        for (let i = 0; i < barChart.length; i++) {

            let data = JSON.parse(barChart[i].dataset.barChart),
                options = (barChart[i].dataset.options !== undefined) ? JSON.parse(barChart[i].dataset.options) : '',
                seriesColor = barChart[i].dataset.seriesColor,
                userColors;

            barChart[i].classList.add('bar-chart-' + i);

            if (seriesColor !== undefined) {

                userColors = JSON.parse(seriesColor);

                for (let n = 0; n < userColors.colors.length; n++) {
                    css = `
        .bar-chart-${i} .ct-series:nth-child(${n + 1}) .ct-bar {
            stroke: ${userColors.colors[n]} !important;
          }
        `;
                    style.appendChild(document.createTextNode(css));
                }
            }

            new Chartist.Bar(barChart[i], data, options);
        }


        // Pie chart
        for (let i = 0; i < pieChart.length; i++) {

            let data = JSON.parse(pieChart[i].dataset.pieChart),
                seriesColor = pieChart[i].dataset.seriesColor,
                userColors;

            pieChart[i].classList.add('cz-pie-chart-' + i);

            if (seriesColor !== undefined) {

                userColors = JSON.parse(seriesColor);

                for (let n = 0; n < userColors.colors.length; n++) {
                    css = `
        .cz-pie-chart-${i} .ct-series:nth-child(${n + 1}) .ct-slice-pie {
            fill: ${userColors.colors[n]} !important;
          }
        `;
                    style.appendChild(document.createTextNode(css));
                }
            }

            new Chartist.Pie(pieChart[i], data, {
                labelInterpolationFnc: function (value) {
                    return Math.round(value / data.series.reduce(sum) * 100) + '%';
                }
            });
        }
    }

    /**
     * Open YouTube / Vimeo video in lightbox
     * @requires @requires https://github.com/sachinchoolur/lightgallery.js
    */

    function videoButton (element) {

        let button = element.querySelectorAll('[data-bs-toggle="video"]');
        if (button.length) {
            for (let i = 0; i < button.length; i++) {
                lightGallery(button[i], {
                    selector: 'this',
                    download: false,
                    videojs: true,
                    youtubePlayerParams: {
                        modestbranding: 1,
                        showinfo: 0,
                        rel: 0
                    },
                    vimeoPlayerParams: {
                        byline: 0,
                        portrait: 0,
                        color: 'fe696a'
                    }
                });
            }
        }
    }

    /**
     * Ajaxify MailChimp subscription form
    */

    function subscriptionForm (element) {

        const form = element.querySelectorAll('.subscription-form');

        if (form === null) return;

        for (let i = 0; i < form.length; i++) {

            let button = form[i].querySelector('button[type="submit"]'),
                buttonText = button.innerHTML,
                input = form[i].querySelector('.form-control'),
                antispam = form[i].querySelector('.subscription-form-antispam'),
                status = form[i].querySelector('.subscription-status');

            form[i].addEventListener('submit', function (e) {
                if (e) e.preventDefault();
                if (antispam.value !== '') return;
                register(this, button, input, buttonText, status);
            });
        }

        let register = (form, button, input, buttonText, status) => {
            button.innerHTML = 'Sending...';

            // Get url for MailChimp
            let url = form.action.replace('/post?', '/post-json?');

            // Add form data to object
            let data = '&' + input.name + '=' + encodeURIComponent(input.value);

            // Create and add post script to the DOM
            let script = document.createElement('script');
            script.src = url + '&c=callback' + data;
            document.body.appendChild(script);

            // Callback function
            let callback = 'callback';
            window[callback] = (response) => {

                // Remove post script from the DOM
                delete window[callback];
                document.body.removeChild(script);

                // Change button text back to initial
                button.innerHTML = buttonText;

                // Display content and apply styling to response message conditionally
                if (response.result == 'success') {
                    input.classList.remove('is-invalid');
                    input.classList.add('is-valid');
                    status.classList.remove('status-error');
                    status.classList.add('status-success');
                    status.innerHTML = response.msg;
                    setTimeout(() => {
                        input.classList.remove('is-valid');
                        status.innerHTML = '';
                        status.classList.remove('status-success');
                    }, 6000);
                } else {
                    input.classList.remove('is-valid');
                    input.classList.add('is-invalid');
                    status.classList.remove('status-success');
                    status.classList.add('status-error');
                    status.innerHTML = response.msg.substring(4);
                    setTimeout(() => {
                        input.classList.remove('is-invalid');
                        status.innerHTML = '';
                        status.classList.remove('status-error');
                    }, 6000);
                }
            };
        };
    }

    /**
     * Range slider
     * @requires https://github.com/leongersen/noUiSlider
    */

    function rangeSlider (element) {

        let rangeSliderWidget = element.querySelectorAll('.range-slider');

        for (let i = 0; i < rangeSliderWidget.length; i++) {

            let rangeSlider = rangeSliderWidget[i].querySelector('.range-slider-ui'),
                valueMinInput = rangeSliderWidget[i].querySelector('.range-slider-value-min'),
                valueMaxInput = rangeSliderWidget[i].querySelector('.range-slider-value-max');

            let options = {
                dataStartMin: parseInt(rangeSliderWidget[i].dataset.startMin, 10),
                dataStartMax: parseInt(rangeSliderWidget[i].dataset.startMax, 10),
                dataMin: parseInt(rangeSliderWidget[i].dataset.min, 10),
                dataMax: parseInt(rangeSliderWidget[i].dataset.max, 10),
                dataStep: parseInt(rangeSliderWidget[i].dataset.step, 10)
            };

            let dataCurrency = rangeSliderWidget[i].dataset.currency;

            noUiSlider.create(rangeSlider, {
                start: [options.dataStartMin, options.dataStartMax],
                connect: true,
                step: options.dataStep,
                pips: {mode: 'count', values: 5},
                tooltips: true,
                range: {
                    'min': options.dataMin,
                    'max': options.dataMax
                },
                format: {
                    to: function (value) {
                        return `${dataCurrency ? dataCurrency : '$'}${parseInt(value, 10)}`;
                    },
                    from: function (value) {
                        return Number(value);
                    }
                }
            });

            rangeSlider.noUiSlider.on('update', (values, handle) => {
                let value = values[handle];
                value = value.replace(/\D/g, '');
                if (handle) {
                    valueMaxInput.value = Math.round(value);
                } else {
                    valueMinInput.value = Math.round(value);
                }
            });

            valueMinInput.addEventListener('change', function () {
                rangeSlider.noUiSlider.set([this.value, null]);
            });

            valueMaxInput.addEventListener('change', function () {
                rangeSlider.noUiSlider.set([null, this.value]);
            });

        }
    }

    /**
     * Filter list of items by typing in the search field
    */

    function filterList (element) {

        let filterListWidget = element.querySelectorAll('.widget-filter');

        for (let i = 0; i < filterListWidget.length; i++) {

            let filterInput = filterListWidget[i].querySelector('.widget-filter-search'),
                filterList = filterListWidget[i].querySelector('.widget-filter-list'),
                filterItems = filterList.querySelectorAll('.widget-filter-item');

            if (!filterInput) {
                continue;
            }

            filterInput.addEventListener('keyup', filterListFunc);

            function filterListFunc() {

                let filterValue = filterInput.value.toLowerCase();

                for (let i = 0; i < filterItems.length; i++) {

                    let filterText = filterItems[i].querySelector('.widget-filter-item-text').innerHTML;

                    if (filterText.toLowerCase().indexOf(filterValue) > -1) {
                        filterItems[i].classList.remove('d-none');
                    } else {
                        filterItems[i].classList.add('d-none');
                    }

                }

            }
        }
    }

    /**
     * Data filtering (Comparison table)
    */

    function dataFilter (element) {

        let trigger = element.querySelector('[data-filter-trigger]'),
            target = element.querySelectorAll('[data-filter-target]');

        if (trigger === null) return;

        trigger.addEventListener('change', function () {
            let selected = this.options[this.selectedIndex].value.toLowerCase();
            if (selected === 'all') {
                for (let i = 0; i < target.length; i++) {
                    target[i].classList.remove('d-none');
                }
            } else {
                for (let n = 0; n < target.length; n++) {
                    target[n].classList.add('d-none');
                }
                document.querySelector('#' + selected).classList.remove('d-none');
            }
        });
    }

    /**
     * Updated the text of the label when radio button changes (mainly for color options)
    */

    function labelUpdate (element) {

        let radioBtns = element.querySelectorAll('[data-bs-label]');

        for (let i = 0; i < radioBtns.length; i++) {
            radioBtns[i].addEventListener('change', function () {
                let target = this.dataset.bsLabel;
                try {
                    document.getElementById(target).textContent = this.value;
                } catch (err) {
                    if (err.message = "Cannot set property 'textContent' of null") {
                        console.error('Make sure the [data-label] matches with the id of the target element you want to change text of!');
                    }
                }
            });
        }
    }

    /**
     * Change tabs with radio buttons
    */

    function radioTab (element) {

        let radioBtns = element.querySelectorAll('[data-bs-toggle="radioTab"]');

        for (let i = 0; i < radioBtns.length; i++) {
            radioBtns[i].addEventListener('click', function () {
                let target = this.dataset.bsTarget,
                    parent = element.querySelector(this.dataset.bsParent),
                    children = parent.querySelectorAll('.radio-tab-pane');

                children.forEach(function (element) {
                    element.classList.remove('active');
                });

                document.querySelector(target).classList.add('active');
            });
        }
    }

    /**
     * Change tabs with radio buttons
    */

    function creditCard (element) {

        let selector = element.querySelector('.credit-card-form');

        if (selector === null) return;

        let card = new Card({
            form: selector,
            container: '.credit-card-wrapper'
        });
    }

    /**
     * Master checkbox that checkes / unchecks all target checkboxes at once
    */

    function masterCheckbox (element) {

        const masterCheckbox = element.querySelectorAll('[data-master-checkbox-for]');

        if (masterCheckbox.length === 0) return;

        for (let i = 0; i < masterCheckbox.length; i++) {

            masterCheckbox[i].addEventListener('change', function () {
                let targetWrapper = element.querySelector(this.dataset.masterCheckboxFor),
                    targetCheckboxes = targetWrapper.querySelectorAll('input[type="checkbox"]');
                if (this.checked) {
                    for (let n = 0; n < targetCheckboxes.length; n++) {
                        targetCheckboxes[n].checked = true;
                        if (targetCheckboxes[n].dataset.checkboxToggleClass) {
                            document.querySelector(targetCheckboxes[n].dataset.target).classList.add(targetCheckboxes[n].dataset.checkboxToggleClass);
                        }
                    }
                } else {
                    for (let m = 0; m < targetCheckboxes.length; m++) {
                        targetCheckboxes[m].checked = false;
                        if (targetCheckboxes[m].dataset.checkboxToggleClass) {
                            document.querySelector(targetCheckboxes[m].dataset.target).classList.remove(targetCheckboxes[m].dataset.checkboxToggleClass);
                        }
                    }
                }
            });
        }
    }

    /**
     * Navigation spinner
     */

    function navigationSpinner (element) {
        [].slice.call(element.querySelectorAll('.navigation-spinner')).forEach(spinner => {
            const initialized = spinner.dataset.state === 'initialized';
            const parent = spinner.parentNode;
            if (initialized) {
                spinner.style.display = 'none';
                parent.disabled = false;
            } else {
                parent.addEventListener('click', () => {
                    setTimeout(() => spinner.style.display = 'inline-block', 100);
                    parent.disabled = true;
                });
                spinner.dataset.state = 'initialized';
            }
        });
    }

    /**
     * Date / time picker
     * @requires https://github.com/flatpickr/flatpickr
     */


     function datePicker (element) {
        let picker = element.querySelectorAll('.date-picker');

        if (picker.length === 0) return;

        for (let i = 0; i < picker.length; i++) {

            let defaults = {
                disableMobile: 'true'
            };

            let userOptions;
            if (picker[i].dataset.datepickerOptions !== undefined) userOptions = JSON.parse(picker[i].dataset.datepickerOptions);
            let linkedInput = picker[i].classList.contains('date-range') ? {"plugins": [new rangePlugin({input: picker[i].dataset.linkedInput})]} : '{}';
            let options = {...defaults, ...linkedInput, ...userOptions};

            flatpickr(picker[i], options);
        }
    }

    /**
     * Force dropdown to work as select box
    */

    function dropdownSelect (element) {

        let dropdownSelectList = element.querySelectorAll('[data-bs-toggle="select"]');

        for (let i = 0; i < dropdownSelectList.length; i++) {
            let dropdownItems = dropdownSelectList[i].querySelectorAll('.dropdown-item'),
                dropdownToggleLabel = dropdownSelectList[i].querySelector('.dropdown-toggle-label'),
                hiddenInput = dropdownSelectList[i].querySelector('input[type="hidden"]');

            for (let n = 0; n < dropdownItems.length; n++) {
                dropdownItems[n].addEventListener('click', function (e) {
                    e.preventDefault();
                    let dropdownLabel = this.querySelector('.dropdown-item-label').innerText;
                    dropdownToggleLabel.innerText = dropdownLabel;
                    if (hiddenInput !== null) {
                        hiddenInput.value = dropdownLabel;
                    }
                });
            }
        }
    }

    /**
     * Click timeout
    */

    function clickTimeout (element) {
        [].slice.call(element.querySelectorAll('[data-click-timeout-duration]')).forEach(
            timeoutElement => {
                let duration = Number(timeoutElement.dataset.clickTimeoutDuration);
                delete timeoutElement.dataset.clickTimeoutDuration;
                if (duration > 0) {
                    let innerHTML = timeoutElement.innerHTML;
                    let text = timeoutElement.dataset.clickTimeoutText || '(TIME...)';
                    timeoutElement.dataset.timeLeft = String(duration);
                    function elapsed() {
                        let timeLeft = Number(timeoutElement.dataset.timeLeft) - 1;
                        timeoutElement.dataset.timeLeft = String(timeLeft);
                        if (timeLeft > 0) {
                            timeoutElement.innerHTML =
                                innerHTML + '<br/>' +
                                '<small>' +
                                    text.replace('TIME', timeLeft) +
                                '</small>';
                            setTimeout(() => elapsed(), 1000);
                        } else {
                            timeoutElement.innerHTML = innerHTML;
                            timeoutElement.click();
                        }
                    }
                    elapsed();
                }
            }
        );
    }

    /**
     * @typedef Toast
     * @type {object}
     * @property {string} text
     * @property {null|string} head
     * @property {boolean} error
     * @property {null|number|string} id
     * @property {boolean} autohide
     */

    /**
     * Create a toast and show it
     * @param {Toast}
     */
    function createToast({ text, head, error, id, autohide }) {
        const idStr = (typeof id === 'string' || typeof id === 'number') ?
            `toast-${id}`.trim() :
            'toast';

        const existing = document.getElementById(idStr);
        if (idStr !== 'toast' && existing !== null) {
            const counter = document.getElementById(`${idStr}-count`);
            const last = counter.innerHTML.match(/\d+/);
            counter.innerHTML = `x${(last ? Number(last[0]) : 1) + 1}`;

            // fake event to reset internal timeout
            existing.dispatchEvent(new FocusEvent('focusin'));
            existing.dispatchEvent(new FocusEvent('focusout'));
            return;
        }

        const element = document.createElement('div');
        element.id = idStr;
        element.classList.add('toast');
        element.setAttribute('data-bs-autohide', autohide.toString());

        {
            const header = element.appendChild(document.createElement('div'));
            header.classList.add(
                'toast-header',
                'text-light',
                'fs-6',
                'fw-bold',
                error === true ? 'bg-warning' : 'bg-success',
            );

            const icon = header.appendChild(document.createElement('i'));
            icon.classList.add('me-2', error ? 'ci-announcement' : 'ci-check-circle');

            const grid = header.appendChild(document.createElement('div'));
            grid.classList.add('d-grid', 'w-100');
            grid.style.gridTemplateColumns = '1fr auto auto';
            grid.style.columnGap = '0.5rem';

            const text = grid.appendChild(document.createElement('span'));
            text.innerHTML = head;

            const count = grid.appendChild(document.createElement('span'));
            count.id = `${idStr}-count`;

            const btn = grid.appendChild(document.createElement('button'));
            btn.type = 'button';
            btn.classList.add('btn-close', 'm-auto');
            btn.setAttribute('aria-label', 'close');
            btn.onclick = _ => bootstrap.Toast.getInstance(element).hide();
        }

        {
            const body = element.appendChild(document.createElement('div'));
            body.classList.add('toast-body');
            body.innerHTML = text;
        }

        document.getElementById('toast-container')
            .appendChild(element);

        const toast = new bootstrap.Toast(element);
        setTimeout(() => toast.show(), 0);
    }

    const KEY = 'data-app-toasts-queue';

    /**
     * @param {Element} element
     */
    function toastQueue(element) {
        element.querySelectorAll(`[${KEY}]`).forEach(toastElement => {
            const dataStr = toastElement.getAttribute(KEY).replace(/'/g, '"');
            toastElement.removeAttribute(KEY);

            /** @type {{queue: Toast[], deferred: boolean}} */
            const { queue, deferred } = JSON.parse(dataStr);

            if (deferred) {
                setTimeout(queue.forEach(toast => createToast(toast)), 3000);
            } else {
                queue.forEach(toast => createToast(toast));
            }
        });
    }

    var activeElementId = null;
    var range = null;

    if (!window.addedListeners) {
        const searchInput = document.getElementById("primary-search-input");
        if (searchInput) {
            /** @type {NodeListOf<HTMLDivElement>} */
            const toolTexts = document.querySelectorAll("header div.navbar div.navbar-toolbar > a.navbar-tool > .navbar-tool-text");
            searchInput.addEventListener("focusin", () => toolTexts.forEach(el => {
                el.style.opacity = "0";
                el.style.width = "0";
            }));
            searchInput.addEventListener("focusout", () => toolTexts.forEach(el => {
                el.style.opacity = "1";
                el.style.width = "";
            }));
        }

        document.body.addEventListener('htmx:beforeSwap', function(evt) {
            activeElementId = document.activeElement.id;
            if (document.activeElement.tagName === 'input') {
                range = [document.activeElement.selectionStart, document.activeElement.selectionEnd];
            }
        });

        document.body.addEventListener('htmx:afterSwap', function(evt) {
            if (evt.target.id !== 'main-content' && (!evt.target.parentElement || evt.target.parentElement.id !== 'main-content')) return;
            function decode(str) {
                const div = document.createElement('div');
                div.innerHTML = decodeURIComponent(str.replace(/\+/g, ' '));
                return div.innerText;
            }
            var title = evt.detail.xhr.getResponseHeader('Set-Page-Title');
            document.title = title ?
                decode(title) :
                document.getElementsByTagName('title')[0].dataset.backup;

            function updateMeta(metaName, content) {
                var meta = document.getElementsByTagName('meta')[metaName];
                if (content) {
                    if (!meta) {
                        meta = document.createElement('meta');
                        document.head.append(meta);
                    }
                    meta.setAttribute('name', metaName);
                    meta.setAttribute('content', decode(content));
                } else if (meta) {
                    meta.remove();
                }
            }
            updateMeta('keywords', evt.detail.xhr.getResponseHeader('Set-Keywords'));
            updateMeta('description', evt.detail.xhr.getResponseHeader('Set-Description'));
        });

        document.getElementById('toast-container')
            .addEventListener('hidden.bs.toast', event => event.target.remove());

        document.body.addEventListener('createToast', ev => createToast(ev.detail));

        window.addedListeners = true;
    }

    window.initializeJsForElement = function (element) {
        stickyNavbar(element);
        stuckNavbarMenuToggle(element);
        masonryGrid(element);
        passwordVisibilityToggle(element);
        pellEditor(element);
        fileDropArea(element);
        formValidation(element);
        smoothScroll(element);
        scrollTopButton(element);
        tooltip(element);
        popover(element);
        privacy(element);
        toast(element);
        disableDropdownAutohide(element);
        carousel(element);
        gallery(element);
        productGallery(element);
        imageZoom(element);
        countdown(element);
        charts(element);
        videoButton(element);
        subscriptionForm(element);
        rangeSlider(element);
        filterList(element);
        dataFilter(element);
        labelUpdate(element);
        radioTab(element);
        creditCard(element);
        masterCheckbox(element);
        navigationSpinner(element);
        datePicker(element);
        dropdownSelect(element);
        clickTimeout(element);
        toastQueue(element);

        if (activeElementId) {
            let activeElement = document.getElementById(activeElementId);
            if (activeElement) {
                activeElement.focus();
                if (
                    range !== null &&
                    (activeElement.tagName === 'input' || activeElement.tagName === 'textarea')
                ) {
                    activeElement.selectionStart = range[0];
                    activeElement.selectionEnd = range[1];
                    range = null;
                }
            }
            activeElementId = null;
        }

        //[].slice.call(element.querySelectorAll('[data-bs-toggle="tooltip"]'))
        //    .map((element) => new bootstrap.Tooltip(element));
        [].slice.call(element.querySelectorAll('[data-bs-toggle="dropdown"]'))
            .map((element) => new bootstrap.Dropdown(element));

        var invalid = document.querySelector('.invalid-feedback');
        if (invalid && invalid.parentElement) invalid.parentElement.scrollIntoView({block: 'center'});
    };

    htmx.onLoad(window.initializeJsForElement);

}());
