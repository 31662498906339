/**
 * Navigation spinner
 */

export default function (element) {
    [].slice.call(element.querySelectorAll('.navigation-spinner')).forEach(spinner => {
        const initialized = spinner.dataset.state === 'initialized';
        const parent = spinner.parentNode;
        if (initialized) {
            spinner.style.display = 'none';
            parent.disabled = false;
        } else {
            parent.addEventListener('click', () => {
                setTimeout(() => spinner.style.display = 'inline-block', 100);
                parent.disabled = true;
            });
            spinner.dataset.state = 'initialized';
        }
    });
}
