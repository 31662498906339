/**
 * Change tabs with radio buttons
*/

export default function (element) {

    let radioBtns = element.querySelectorAll('[data-bs-toggle="radioTab"]');

    for (let i = 0; i < radioBtns.length; i++) {
        radioBtns[i].addEventListener('click', function () {
            let target = this.dataset.bsTarget,
                parent = element.querySelector(this.dataset.bsParent),
                children = parent.querySelectorAll('.radio-tab-pane');

            children.forEach(function (element) {
                element.classList.remove('active');
            });

            document.querySelector(target).classList.add('active');
        });
    }
}
