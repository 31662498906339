/**
 * Animate scroll to top button in/off view
*/

export default function (element) {

    let ele = element.querySelector('.btn-scroll-top'),
        scrollOffset = 600;

    if (ele == null) return;

    let offsetFromTop = parseInt(scrollOffset, 10);

    window.addEventListener('scroll', (e) => {
        if (e.currentTarget.pageYOffset > offsetFromTop) {
            ele.classList.add('show');
        } else {
            ele.classList.remove('show');
        }
    });
}
