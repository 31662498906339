/**
 * Image zoom on hover (used inside Product Gallery)
 * @requires https://github.com/imgix/drift
*/

export default function (element) {

    let images = element.querySelectorAll('.image-zoom');

    for (let i = 0; i < images.length; i++) {
        new Drift(images[i], {
            paneContainer: images[i].parentElement.querySelector('.image-zoom-pane')
        });
    }

}
