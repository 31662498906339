/**
 * Toast
 * @requires https://getbootstrap.com
*/

export default function (element) {

    let toastElList = [].slice.call(document.querySelectorAll('.toast'));

    let toastList = toastElList.map((toastEl) => new bootstrap.Toast(toastEl));

}
