/**
 * Click timeout
*/

export default function (element) {
    [].slice.call(element.querySelectorAll('[data-click-timeout-duration]')).forEach(
        timeoutElement => {
            let duration = Number(timeoutElement.dataset.clickTimeoutDuration);
            delete timeoutElement.dataset.clickTimeoutDuration;
            if (duration > 0) {
                let innerHTML = timeoutElement.innerHTML;
                let text = timeoutElement.dataset.clickTimeoutText || '(TIME...)';
                timeoutElement.dataset.timeLeft = String(duration);
                function elapsed() {
                    let timeLeft = Number(timeoutElement.dataset.timeLeft) - 1;
                    timeoutElement.dataset.timeLeft = String(timeLeft);
                    if (timeLeft > 0) {
                        timeoutElement.innerHTML =
                            innerHTML + '<br/>' +
                            '<small>' +
                                text.replace('TIME', timeLeft) +
                            '</small>';
                        setTimeout(() => elapsed(), 1000);
                    } else {
                        timeoutElement.innerHTML = innerHTML;
                        timeoutElement.click();
                    }
                }
                elapsed();
            }
        }
    );
}
