/**
 * Disable dropdown autohide when select is clicked
*/

export default function (element) {

    let elements = element.querySelectorAll('.disable-autohide .form-select');

    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }
}
