/**
 * Menu toggle for 3 level navbar stuck state
*/

export default function (element) {

    let toggler = element.querySelector('.navbar-stuck-toggler'),
        stuckMenu = element.querySelector('.navbar-stuck-menu');

    if (toggler == null) return;

    toggler.addEventListener('click', function (e) {
        stuckMenu.classList.toggle('show');
        e.preventDefault();
    });

}
