/**
 * Change tabs with radio buttons
*/

export default function (element) {

    let selector = element.querySelector('.credit-card-form');

    if (selector === null) return;

    let card = new Card({
        form: selector,
        container: '.credit-card-wrapper'
    });
}
