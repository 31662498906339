/**
 * Content carousel with extensive options to control behaviour and appearance
 * @requires https://github.com/ganlanyuan/tiny-slider
*/

export default function (element) {
    const carousels = element.querySelectorAll('.tns-carousel .tns-carousel-inner');
    for (const carousel of carousels) {
        const defaults = {
            loop: false,
            container: carousel,
            controlsText: ['<i class="ci-arrow-left"></i>', '<i class="ci-arrow-right"></i>'],
            navPosition: 'bottom',
            mouseDrag: true,
            speed: 500,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
        };
        const { carouselOptions } = carousel.dataset;

        const userOptions = carouselOptions !== undefined ? JSON.parse(carouselOptions) : {};
        if (!carousel.tns) {
            const lastHeadElement = document.head.lastElementChild;
            carousel.tns = tns({ ...defaults, ...userOptions });
            const newStyleElement = document.head.lastElementChild;
            if (lastHeadElement !== newStyleElement && newStyleElement.tagName === 'STYLE') {
                newStyleElement.carouselElement = carousel;
                newStyleElement.dataset.owner = 'carousel';
            }
        }
    }
    for (const style of document.querySelectorAll('style[data-owner="carousel"]')) {
        if (!document.body.contains(style.carouselElement)) style.remove();
    }
}
